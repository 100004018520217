<template>
  <div id="SlidesContainer" class="page-slides slides smooth smoothScroll animated noPreload">
    <SvgLibrary></SvgLibrary>
    <Panels></Panels>

    <!-- Slide 1 (#03) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-7-12">
              <div class="fix-3-12 margin-bottom-3">
                <svg height="77" class="wide ae-1 fromCenter">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#logo"></use>
                </svg>
              </div>
              <div class="fix-6-12">
                <p class="large cropBottom ae-2"><span class="opacity-8">In a meat-eating world, wearing leather for shoes and even clothes, the discussion of fur is&nbsp;childish.</span></p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-03.jpg)"></div>
    </section>
    <!-- Slide 2 (#16) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-12-12">
              <ul class="flex fixedSpaces reverse">
                <li class="col-5-12 left">
                  <h1 class="margin-top-1 margin-bottom-2 ae-1 fromLeft">Great design is transparent</h1>
                  <p class="large ae-2 fromLeft"><span class="opacity-8">A chair is the first thing you need when you don&rsquo;t really need anything, and is therefore a peculiarly compelling symbol of&nbsp;civilization.</span></p>
                  <a href="#" class="button blue rounded crop ae-3 fromCenter">Get Started</a>
                </li>
                <li class="col-1-12"></li>
                <li class="col-6-12">
                  <div class="videoThumbnail rounded shadow popupTrigger ae-4 fromCenter margin-bottom-tablet-4" data-popup-id="16-2">
                    <img class="wide" src="assets/img/video-16.jpg" alt="Video Thumbnail" />
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-16.jpg)"></div>
    </section>

    <!-- Popup Video -->
    <div class="popup autoplay" data-popup-id="16-2">
      <div class="close"><svg>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg></div>
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-10-12">
              <div class="embedVideo popupContent shadow rounded">
                <iframe data-src="https://www.youtube.com/embed/g8yBqxTiHSs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Slide 3 (#30) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-12-12 left">
              <div class="fix-8-12 toLeft">
                <h1 class="smaller margin-bottom-2 ae-1">Breath of art</h1>
                <p class="large margin-bottom-1 ae-2 toLeft"><span class="opacity-8">Problems with visual design can turn users off so quickly that they never discover all the smart choices you made with navigation or interaction&nbsp;design.</span></p>
              </div>

              <ul class="flex margin-top-4">
                <li class="col-3-12 margin-bottom-1 left ae-3">
                  <h4 class="smallest">Meet Quick</h4>
                  <p class="small opacity-8">I think it's really important to design things with a kind of personality.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-4">
                  <h4 class="smallest">Better Interface</h4>
                  <p class="small opacity-8">Designers are very fickle. I never wanted to be a victim of that.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-5">
                  <h4 class="smallest">Fast Connection</h4>
                  <p class="small opacity-8">The artist is a collector of things imaginary or real.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-6">
                  <h4 class="smallest">Listen Your Mind</h4>
                  <p class="small opacity-8">The details are the very source of expression in architecture.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-7">
                  <h4 class="smallest">Fast Connection</h4>
                  <p class="small opacity-8">The artist is a collector of things imaginary or real.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-8">
                  <h4 class="smallest">Listen Your Mind</h4>
                  <p class="small opacity-8">The details are the very source of expression in architecture.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-9">
                  <h4 class="smallest">Meet Quick</h4>
                  <p class="small opacity-8">I think it's really important to design things with a kind of personality.</p>
                </li>
                <li class="col-3-12 margin-bottom-1 left ae-10">
                  <h4 class="smallest">Better Interface</h4>
                  <p class="small opacity-8">Designers are very fickle. I never wanted to be a victim of that.</p>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-30.jpg)"></div>
    </section>
    <!-- Slide 4 (#67) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-8-12 toCenter">
              <h1 class="smaller margin-bottom-2 ae-1">Request early access</h1>
              <p class="large ae-2"><span class="opacity-8">Share our great design quotes collection with funny, inspirational and motivational quotations by famous authors, designers on design and designing.</span></p>
            </div>
            <div class="fix-12-12 margin-top-4">
              <ul class="flex later fixedSpaces equal left">
                <li class="col-4-12 ae-3 fromCenter">
                  <a href="#" class="box-67">
                    <div class="thumbnail-67 rounded">
                      <img src="assets/img/gallery-67-1.jpg" class="wide" />
                    </div>
                    <div class="name-67 equalElement table wide">
                      <div class="cell">
                        <p class="smallest bold uppercase margin-bottom-1 opacity-4">3 minutes • LEARN MORE</p>
                        <h4 class="small cropBottom">Artisan Rye Bread</h4>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-4-12 ae-4 fromCenter">
                  <a href="#" class="box-67">
                    <div class="thumbnail-67 rounded">
                      <img src="assets/img/gallery-67-2.jpg" class="wide" />
                    </div>
                    <div class="name-67 equalElement table wide">
                      <div class="cell">
                        <p class="smallest bold uppercase margin-bottom-1 opacity-4">14 minutes • LEARN MORE</p>
                        <h4 class="small cropBottom">Blueberry Muffin</h4>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-4-12 ae-5 fromCenter">
                  <a href="#" class="box-67">
                    <div class="thumbnail-67 rounded">
                      <img src="assets/img/gallery-67-3.jpg" class="wide" />
                    </div>
                    <div class="name-67 equalElement table wide">
                      <div class="cell">
                        <p class="smallest bold uppercase margin-bottom-1 opacity-4">12 minutes • LEARN MORE</p>
                        <h4 class="small cropBottom">Chopped Parsley</h4>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-67.jpg)"></div>
    </section>
    <!-- Slide 5 (#62) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-12-12">
              <ul class="flex reverse noSpaces verticalCenter">
                <li class="col-6-12 left">
                  <h1 class="smaller margin-bottom-2 ae-2">Magical Balance</h1>
                  <p class="large margin-bottom-4 ae-3"><span class="opacity-8">Design solves problems. It is the answer to question; you need to understand that first in order to answer the riddle.</span></p>
                  <ul class="flex fixedSpaces flex-62 later">
                    <li class="col-6-12 ae-4">
                      <i class="material-icons">panorama</i>
                      <h4 class="smaller margin-bottom-1">HQ Photos</h4>
                      <p class="small opacity-8">Design is the conscious effort to impose a meaningful order.</p>
                    </li>
                    <li class="col-6-12 ae-5">
                      <i class="material-icons">settings_applications</i>
                      <h4 class="smaller margin-bottom-1">Useful Components</h4>
                      <p class="small opacity-8">Design is as much a matter of finding problems as it is solving them..</p>
                    </li>
                    <li class="col-6-12 ae-6">
                      <i class="material-icons">videocam</i>
                      <h4 class="smaller margin-bottom-1">Video Calls</h4>
                      <p class="small opacity-8">It’s all fun and games until someone breaks a link.</p>
                    </li>
                    <li class="col-6-12 ae-7">
                      <i class="material-icons">face</i>
                      <h4 class="smaller margin-bottom-1">Photo Profile</h4>
                      <p class="small opacity-8">The definition of an expert is someone who knows what not to do.</p>
                    </li>
                  </ul>
                </li>
                <li class="col-6-12 left ae-1">
                  <img class="macbook-62 margin-bottom-tablet-3" src="assets/img/macbook-62.png" data-action="zoom" width="1005" alt="Macbook Image" />
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-62.jpg)"></div>
    </section>
    <!-- Slide 6 (#75) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-8-12 toCenter">
              <h1 class="smaller margin-bottom-2 ae-1">Smoke & Mirrors</h1>
              <p class="large ae-2"><span class="opacity-8">Design is a form of com­pet­it­ive advant­age. People tend to think of design as good art, good visual lan­guage, which it abso­lutely has to be.</span></p>
            </div>
            <div class="fix-12-12 margin-top-4">
              <ul class="flex flex-75 fixedSpaces ae-3 popupTrigger noText" data-popup-id="75-6">
                <li class="col-3-12 ae-6 fadeIn">
                  <img src="assets/img/gallery-75-1.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="0" alt="Image" />
                  <img src="assets/img/gallery-75-2.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="1" alt="Image" />
                </li>
                <li class="col-3-12 ae-3 fadeIn">
                  <img src="assets/img/gallery-75-3.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="2" alt="Image" />
                </li>
                <li class="col-3-12 ae-2 fadeIn">
                  <img src="assets/img/gallery-75-4.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="3" alt="Image" />
                  <img src="assets/img/gallery-75-5.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="4" alt="Image" />
                </li>
                <li class="col-3-12 ae-7 fadeIn">
                  <img src="assets/img/gallery-75-6.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="5" alt="Image" />
                  <img src="assets/img/gallery-75-7.jpg" class="wide rounded margin-bottom-2" data-slider-id="75-6" data-slider-action="6" alt="Image" />
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-75.jpg)"></div>
    </section>

    <!-- Popup Gallery -->
    <div class="popup animated" data-popup-id="75-6">
      <div class="close"><svg>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg></div>
      <div class="content">
        <div class="container">
          <div class="wrap spaces">

            <ul class="slider animated ae-1 fromAbove inlineBlock clickable popupContent disableSelect" data-slider-id="75-6" style="display: inline-block;">
              <li class="selected">
                <img src="assets/img/gallery-75-full-1.jpg" class="rounded" alt="Image" />
              </li>
              <li>
                <img src="assets/img/gallery-75-full-2.jpg" class="rounded" alt="Image" />
              </li>
              <li>
                <img src="assets/img/gallery-75-full-3.jpg" class="rounded" alt="Image" />
              </li>
              <li>
                <img src="assets/img/gallery-75-full-4.jpg" class="rounded" alt="Image" />
              </li>
              <li>
                <img src="assets/img/gallery-75-full-5.jpg" class="rounded" alt="Image" />
              </li>
              <li>
                <img src="assets/img/gallery-75-full-6.jpg" class="rounded" alt="Image" />
              </li>
              <li>
                <img src="assets/img/gallery-75-full-7.jpg" class="rounded" alt="Image" />
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>

    <!-- Slide 7 (#82) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-7-12 noSelect">
              <div class="relative">
                <div class="leftControl-82 ae-5 fromLeft" data-slider-id="82-7" data-slider-action="prev"><svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-left"></use>
                  </svg></div>
                <div class="rightControl-82 ae-5 fromRight" data-slider-id="82-7" data-slider-action="next"><svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-right"></use>
                  </svg></div>
                <ul class="slider clickable animated margin-bottom-2 ae-1 fadeIn" data-slider-id="82-7">
                  <li class="selected"><img src="assets/img/iphone-82-1.png" class="wide" alt="iPhone Thumbnail" /></li>
                  <li><img src="assets/img/iphone-82-2.png" class="wide" alt="iPhone Thumbnail" /></li>
                  <li><img src="assets/img/iphone-82-3.png" class="wide" alt="iPhone Thumbnail" /></li>
                  <li><img src="assets/img/iphone-82-4.png" class="wide" alt="iPhone Thumbnail" /></li>
                </ul>
                <ul class="controller dots ae-3 fromBottom" data-slider-id="82-7">
                  <li class="dot selected"></li>
                  <li class="dot"></li>
                  <li class="dot"></li>
                  <li class="dot"></li>
                </ul>
              </div>
              <div class="fix-5-12 margin-top-2">
                <h1 class="smaller margin-bottom-2 ae-5 fronLeft">The Main Reason</h1>
                <div class="ae-6 fromrRight">
                  <p class="large margin-bottom-3 opacity-8">There&rsquo;s a subtle reason that programmers always want to throw away the code and start over.</p>
                  <a href="#" class="button blue rounded cropBottom ae-7 fromCenter"><i class="material-icons">textsms</i> Say Hello</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-82.jpg)"></div>
    </section>

    <!-- Popup Video -->
    <div class="popup autoplay" data-popup-id="82-7">
      <div class="close"><svg>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg></div>
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-10-12">
              <div class="embedVideo popupContent shadow rounded">
                <iframe data-src="https://www.youtube.com/embed/g8yBqxTiHSs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Slide 8 (#99) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap padding-top-phablet-5">

            <div class="fix-12-12 ae-1">
              <div class="swiper-99">
                <div class="swiper-wrapper">
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-1.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">How to start a fide in the wet forest</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-2.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">How to find a cover in the dust storm</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-3.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">How to make a quick shelter in a mountain</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-4.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">Practical tricks to stay afloat in water</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-1.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">How to start a fide in the wet forest</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-2.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">How to find a cover in the dust storm</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-3.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">How to make a quick shelter in a mountain</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                  <a href="#" class="box-99 swiper-slide noText cursorGrab">
                    <div class="thumbnail-99 text-white disableSelect">
                      <img src="assets/img/gallery-99-4.jpg" alt="Picture" class="wide rounded" />
                      <h4 class="smaller title-slide-99">Practical tricks to stay afloat in water</h4>
                      <p class="more-99 smallest uppercase bold opacity-8">Learn More</p>
                      <div class="blackout-99 rounded"></div>
                    </div>
                  </a>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination ae-3"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-99.jpg)"></div>
    </section>

    <!-- Slide 9 (#86) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">

            <div class="fix-12-12 left">
              <div class="fix-6-12 toLeft">
                <h1 class="margin-bottom-5 ae-1 fromLeft">Only work with people you&rsquo;d invite to your home for a meal.</h1>
                <div class="popupTrigger ae-3 fromRight relative margin-top-4" data-popup-id="86-9">
                  <span class="button play white button-86 absolute">
                    <svg>
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#play"></use>
                    </svg>
                  </span>
                  <div class="text-86">
                    <p class="smaller bold uppercase cropBottom">Watch video</p>
                    <p class="cropBottom small opacity-8">See Slides in action (2:00)</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-86.jpg)"></div>
    </section>

    <!-- Popup Video -->
    <div class="popup autoplay" data-popup-id="86-9">
      <div class="close"><svg>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg></div>
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-10-12">
              <div class="embedVideo popupContent shadow rounded">
                <iframe data-src="https://www.youtube.com/embed/g8yBqxTiHSs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Slide 10 (#95) -->
    <section class="slide fade-6 kenBurns">
      <div class="content">
        <div class="container">
          <div class="wrap">
            <h1 class="ae-1 margin-bottom-2">Download Mobile App</h1>
            <p class="larger light ae-2 margin-bottom-4"><span class="opacity-8">Learning never exhausts the mind.</span></p>

            <div class="fix-5-12">
              <form action="#" autocomplete="off" class="slides-form margin-bottom-3">
                <input type="email" class="rounded ae-3" name="email" placeholder="E-mail address" />
                <button type="submit" class="button blue rounded ae-4" name="submit">Try it Free</button>
              </form>
            </div>

            <a href="#" class="button hollow ae-5"><img src="assets/img/appstore.jpg" height="52" /></a><a href="#" class="button hollow ae-6"><img src="assets/img/googleplay.jpg" height="52" /></a>
          </div>

        </div>
      </div>
      <div class="background" style="background-image:url(assets/img/background/img-95.jpg)"></div>
    </section>

  </div>

</template>

<script>
  import { slidesFunction } from "../utils/slides_v5.2.1";
  import SvgLibrary from "../components/SvgLibrary.vue";
  import Panels from "../components/Panels.vue";

  export default {
    name: "home",
    components: {
      SvgLibrary,
      Panels,
    },
    data() {
      return {};
    },
    created() {
      document.title = "Slides + VUE";
    },
    mounted() {
      setTimeout(() => {
        this.onCreateView();
      }, 100);
    },
    watch: {},
    destroyed() {
      this.removeHome();
    },
    methods: {
      onCreateView() {
        document.querySelector("body").classList.add("posr");
        slidesFunction();
        window.scrollTo(0, 1);
      },
      removeHome() {
        $("body").removeClass("posr");
        $(window).off("mousemove");
      },
    },
  };
</script>

<style lang="scss">
  .posr {
    position: relative;
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
  }
  @media screen and (max-width: 767px) {
  }
</style>
