<template>
  <div class="panels">
    <!-- Loading Progress Bar -->
    <div class="progress-bar blue"></div>
    <!-- Side Navigation -->
    <nav class="side line">
      <div class="navigation default">
        <ul></ul>
      </div>
    </nav>
    <!-- Panel Top -->
    <nav class="panel top">
      <div class="sections desktop">
        <div class="left"><a href="#" title="Slides Framework"><svg style="width:181px;height:24px">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#logo"></use>
            </svg></a></div>
        <div class="center">
          <ul class="menu">
            <li><a href="#slide1">HOME</a></li>
            <li><a href="#slide3">FEATURES</a></li>
            <li><a href="#slide4">ABOUT</a></li>
            <li><a href="#slide5">SUBSCRIBE</a></li>
          </ul>
        </div>
        <div class="right"><a class="button square" href="#slide5">Try Alpha</a></div>
      </div>
      <div class="sections compact">
        <div class="left">
          <span class="button actionButton sidebarTrigger" data-sidebar-id="1">
            <svg style="width:16px;height:16px">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#menu"></use>
            </svg></span>
          <a href="#slide1" title="Slides Framework"><svg style="width:122px;height:16px">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#logo"></use>
            </svg></a>
        </div>
        <div class="right">
          <a class="button square" href="#slide5">Try Alpha</a>
        </div>
      </div>
    </nav>
    <!-- Sidebar -->
    <nav class="sidebar" data-sidebar-id="1">
      <div class="close"><svg>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg></div>
      <div class="content">
        <ul class="menu">
          <li><a href="#slide1">HOME</a></li>
          <li><a href="#slide3">FEATURES</a></li>
          <li><a href="#slide4">ABOUT</a></li>
          <li><a href="#slide5">SUBSCRIBE</a></li>
        </ul>
        <ul class="social opacity-8">
          <li>
            <a target="_blank" href="https://twitter.com/ZettaTrade"><svg style="width: 28px; height: 28px">
                <use xlink:href="#social-twitter"></use>
              </svg></a>
          </li>
          <li>
            <a target="_blank" href="https://discord.gg/px42xPBFkN"><svg style="width: 28px; height: 28px">
                <use xlink:href="#social-discord"></use>
              </svg></a>
          </li>
          <li>
            <a target="_blank" href="https://github.com/zettablock"><svg style="width: 28px; height: 28px">
                <use xlink:href="#social-github"></use>
              </svg></a>
          </li>
          <li>
            <a target="_blank" href="https://zettablock.medium.com/"><svg style="width: 28px; height: 28px">
                <use xlink:href="#social-medium"></use>
              </svg></a>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Panel Bottom -->
    <nav class="panel bottom hidden">
      <div class="sections desktop">
        <div class="left"><span class="opacity-8">Built with <a href="#" title="Slides Framework"><svg style="width:52px;height:21px">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#logo"></use>
              </svg></a></span></div>
        <div class="center"><span class="opacity-8 semiBold">No risk trial &nbsp;•&nbsp; Support &nbsp;•&nbsp; Frequent updates</span></div>
        <div class="right"><span data-dropdown-id="2" class="button actionButton dropdownTrigger"><svg>
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#share"></use>
            </svg></span></div>
      </div>
      <div class="sections compact hidden">
        <div class="left">Built with <a href="#" title="Slides Framework"><svg style="width:58px;height:16px">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#logo"></use>
            </svg></a></div>
        <div class="right"><span data-dropdown-id="2" class="button actionButton dropdownTrigger"><svg>
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#share"></use>
            </svg></span></div>
      </div>
    </nav>

    <!-- Share Window -->
    <div class="dropdown share bottom right" data-dropdown-id="2" data-text="Take a look at this" data-url="https://designmodo.com" data-pinterest-image="https://designmodo.com/wp-content/uploads/2015/10/Presentation.jpg">
      <div class="center padding-2">
        <div class="title">Share</div>
        <a href="#">Contact us</a>
      </div>
      <ul>
        <li class="social-facebook"><svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#fb-like"></use>
          </svg></li>
        <li class="social-twitter"><svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#twitter"></use>
          </svg></li>
        <li class="social-pinterest"><svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#pinterest"></use>
          </svg></li>
        <li class="social-linkedin"><svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#linkedin"></use>
          </svg></li>
        <li class="mail" data-subject="Subject" data-body="Body">share by email</li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "mainNav",
    components: {},
    data() {
      return {};
    },
    methods: {},
  };
</script>

<style lang="scss">
  .panel.top {
    padding: 20px 40px;
  }
  .menu {
    li a {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      opacity: 0.8;
    }
  }
  .right {
    a {
      background: #03ffe1;
      color: #000;
      font-weight: bold;
      font-size: 16px;
      padding: 10px 24px;
      position: relative;
      overflow: unset;
      &:before {
        content: "";
        position: absolute;
        bottom: -32px;
        right: 0;
        width: 0px;
        height: 0px;
        border: 16px solid;
        border-color: #03ffe1 transparent transparent;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
  }
  @media screen and (max-width: 767px) {
    .panel.top {
      padding: 0;
      .sections.compact {
        background: rgba(50, 50, 50, 0.8);
        backdrop-filter: blur(50px);
        .left {
          width: 65%;
          display: flex;
          font-size: auto;
          margin-left: 5px;
          .sidebarTrigger {
            display: block;
            padding: 16px 15px;
            width: 50px;
            height: 50px;
            overflow: unset;
            background: transparent;
            box-shadow: unset;
          }
          a {
            padding: 17px 0px;
            height: 50px;
            svg {
              vertical-align: top;
            }
          }
        }
        .right {
          width: 35%;
          a {
            display: block;
            height: 50px;
            padding: 0px 15px;
            line-height: 50px;
          }
        }
      }
    }
    .side.line {
      display: none;
    }
    .sidebar {
      .menu li a {
        font-size: 24px;
        line-height: 2;
      }
    }
  }
</style>
