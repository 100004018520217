<template>
  <div id="SlidesContainer" class="slides page-slides scroll slow animated noPreload">
    <SvgLibrary></SvgLibrary>
    <Panels></Panels>

    <!-- Slide 1 (#03) -->
    <section class="slide slide1" id="slide1">
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-12-12 clearBoth">
              <div class="fix-5-12 right toRight stR">
                <h4 class="ae-3 fromRight">
                  Building Essential Infrastructure<br> For Crypto Investment.
                </h4>
              </div>
            </div>
            <div class="fix-12-12 sb clearBoth">
              <div class="fix-5-12 toLeft left sbL">
                <h3 class="ae-2 fromLeft"><span></span>Trade with the</h3>
                <h2 class="ae-3 fromLeft"><span>Smart</span> <span>Money</span></h2>
                <div class="bList ae-4 fromLeft">
                  <a href="#" class="button square">Subscribe</a>
                  <a href="#" class="button square">Early Access</a>
                </div>
              </div>
              <div class="fix-4-12 toRight right sbR">
                <div class="demoVideo ae-4 fromRight">
                  <div class="playbutton rounded shadow popupTrigger" data-popup-id="16-2-remove">
                    <svg style="width: 48px; height: 48px">
                      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#playbutton"></use>
                    </svg>
                  </div>
                  <img src="img/video-cover.png" alt="" />
                </div>

                <p class="ae-5 fromRight">Preview Demo</p>
              </div>
            </div>
            <div class="fix-12-12 power clearBoth">
              <span>Powered by</span>
              <img src="img/solana.svg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="background opacity-8">
        <video id="slide1-video" poster="img/slide1-bg.png" preload="auto" x5-video-player-type="h5" x5-playsinline webkit-playsinline playsinline muted autoplay loop>
          <source src="img/slide1-bg.mp4" type="video/mp4" />
        </video>
      </div>
    </section>

    <!-- Popup Video -->
    <div class="popup autoplay" data-popup-id="16-2">
      <div class="close">
        <svg>
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg>
      </div>
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-10-12">
              <div class="embedVideo popupContent shadow rounded">
                <iframe style="display: none;" data-src="https://www.youtube.com/embed/qWSW5ClFQxY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Slide 2 (#16) -->
    <section class="slide slide2" id="slide2">
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-12-12">
              <h3 class="left ae-2 fromBottom">Our Advantages</h3>
              <ul class="flex fixedSpaces reverse">
                <li class="col-3-12 left ae-3 fromBottom" style="margin-top: 300px">
                  <svg style="width: 60px; height: 30px">
                    <use xlink:href="#adv1"></use>
                  </svg>
                  <h5 class="margin-top-1 margin-bottom-1">Data & Analytics</h5>
                  <p class="opacity-6">
                    Access to all on-chain data for supported chains, any type of analytics
                  </p>
                </li>
                <li class="col-3-12 left ae-4 fromBottom" style="margin-top: 200px">
                  <svg style="width: 60px; height: 30px">
                    <use xlink:href="#adv2"></use>
                  </svg>
                  <h5 class="margin-top-1 margin-bottom-1">serverless</h5>
                  <p class="opacity-6">
                    Focus on business logics only,<br />we take care of the
                    rest.
                  </p>
                </li>
                <li class="col-3-12 left ae-5 fromBottom" style="margin-top: 100px">
                  <svg style="width: 60px; height: 30px">
                    <use xlink:href="#adv3"></use>
                  </svg>
                  <h5 class="margin-top-1 margin-bottom-1">
                    Sub-second latency
                  </h5>
                  <p class="opacity-6">
                    Follow the smart money,<br />
                    real time
                  </p>
                </li>
                <li class="col-3-12 left ae-6 fromBottom">
                  <svg style="width: 60px; height: 30px">
                    <use xlink:href="#adv4"></use>
                  </svg>
                  <h5 class="margin-top-1 margin-bottom-1">ActionABLE</h5>
                  <p class="opacity-6">
                    Trigger notification, direct<br />
                    trading, any API calls
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="background" style="background: #000000"></div>
    </section>

    <!-- Slide 3 (#30) -->
    <section class="slide slide3" id="slide3">
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-12-12 margin-bottom-10">
              <h3 class="left ae-1 fromLeft">Key Features</h3>
            </div>
            <div class="fix-12-12 margin-bottom-16 clearBoth">
              <div class="fix-5-12 left toLeft keyInfo">
                <svg class="ae-2 fromLeft" style="width: 60px; height: 70px">
                  <use xlink:href="#key1"></use>
                </svg>
                <h2 class="margin-top-2 margin-bottom-1 ae-3 fromLeft">
                  STATE OF THE ART
                </h2>
                <h3 class="margin-bottom-3 ae-4 fromLeft">
                  DATA infrastructure
                </h3>
                <p class="opacity-6 ae-5 fromLeft">
                  Built by infrastructure engineers from the best data driven companies in Silicon Valley, deploy data applications without complex microservices, all you need is SQL.
                </p>
              </div>
              <div class="fix-6-12 toRight key1 ae-4 fromRight">
                <img src="img/key1.png" alt="" />
              </div>
            </div>
            <div class="fix-12-12 margin-bottom-16 clearBoth">
              <div class="fix-6-12 toLeft key2 ae-4 fromLeft">
                <img src="img/key2.png" alt="" />
              </div>
              <div class="fix-5-12 right toRight keyInfo">
                <svg class="ae-3 fromRight" style="width: 60px; height: 70px">
                  <use xlink:href="#key2"></use>
                </svg>
                <h2 class="margin-top-2 margin-bottom-1 ae-4 fromRight">
                  Discover
                </h2>
                <h3 class="margin-bottom-3 ae-5 fromRight">
                  new opportunities
                </h3>
                <p class="opacity-6 ae-6 fromRight" style="padding-left:50px;">
                  Identify the most prominent wallets from comprehensive on-chain data. Create your own alerts, charts and dashboard using SQL.
                </p>
              </div>
            </div>
            <div class="fix-12-12 clearBoth">
              <div class="fix-5-12 left toLeft keyInfo">
                <svg class="ae-5 fromLeft" style="width: 60px; height: 70px">
                  <use xlink:href="#key3"></use>
                </svg>
                <h2 class="margin-top-2 margin-bottom-1 ae-6 fromLeft">
                  Trade
                </h2>
                <h3 class="margin-bottom-3 ae-7 fromLeft">
                  Ahead of the curve
                </h3>
                <p class="opacity-6 ae-8 fromLeft fromLeft">
                  Automated trading in sub-seconds when a prominent wallet has
                  been moving its funds.
                </p>
              </div>
              <div class="fix-6-12 toRight key3 ae-6 fromRight">
                <img src="img/key3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="background" style="background: #000000"></div>
    </section>
    <!-- Slide 4 (#67) -->
    <section class="slide slide4" id="slide4">
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-12-12 head clearBoth">
              <h3 class="left margin-bottom-4 ae-1 fromBottom">Who are we?</h3>
              <p class="fix-5-12 left opacity-6 toLeft ae-2 fromBottom">
                We are a team of 9 including infra engineers, data engineers,
                data scientists, and blockchain engineers who have worked in
                both traditional markets and in the blockchain ecosystem. <br />
                We bring this combined experience together to create ZettaTrade
                with the mission to give blockchain ecosystem participants the
                infrastructure and analytical tools that they need to deeply
                understand on-chain activities and make the best trading
                decisions.
              </p>
            </div>
            <div class="fix-12-12 teamList desktop">
              <div class="fix-3-12 toLeft">
                <div class="team ae-3 fromBottom">
                  <div class="name">Jeff</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team1.png" alt="" />
                  </div>
                  <p>
                    Fullstack engineer who doesn’t like to write css. Worked in
                    several startups before, the website you saw is built by me
                    and designed by my friend. If you like the style, well, I
                    could make an intro to the designer.
                  </p>
                </div>
              </div>
              <div class="fix-3-12 toLeft">
                <div class="team ae-4 fromBottom">
                  <div class="name">Erwin</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team2.png" alt="" />
                  </div>
                  <p>
                    Engineering, engineering, and engineering. I love building
                    things from scratch and teaching others about how to build
                    big data systems. Designed and built an analytics platform
                    that served 200+ banks.
                  </p>
                </div>
                <div class="team ae-5 fromBottom">
                  <div class="name">Fan</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team3.png" alt="" />
                  </div>
                  <p>
                    Blockchain developer, father of a little baby girl, and got
                    a Ph.D. in EE from UCLA. I love everything about Ethereum.
                    Worked on the Solana project unwillingly just for now.
                  </p>
                </div>
              </div>
              <div class="fix-3-12 toLeft">
                <div class="team ae-4 fromBottom">
                  <div class="name">Chi Zhang</div>
                  <div class="title">CEO, Co-Founder</div>
                  <div class="avatar">
                    <img src="img/team4.png" alt="" />
                  </div>
                  <p>
                    Data Geek, Professional Athlete, degrees in Math, Economics,
                    Biomedical and Statistics. Ph.D. from UC Berkeley. Before
                    Zettatrade, I was a product manager at Databricks, and a
                    founding data scientist at an Auto ML company.
                  </p>
                </div>
                <div class="team ae-5 fromBottom">
                  <div class="name">Peter</div>
                  <div class="title">Developer</div>
                  <div class="avatar">
                    <img src="img/team5.png" alt="" />
                  </div>
                  <p>
                    Cloud Architect, DevOps, SRE, System Engineer for startups
                    with 20 yoe.I build modern, scalable, geographically
                    distributed solutions focused on cloud transformation. Here
                    are many cool words like Devoooops ;)
                  </p>
                </div>
                <div class="team ae-6 fromBottom">
                  <div class="name">Rachel</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team6.png" alt="" />
                  </div>
                  <p>
                    Developer, Music lover, went to graduate school at CMU. I’m
                    a passionate new grad. Proud to build a pipeline that
                    ingested 44 billion Solana transactions data within 2
                    months.
                  </p>
                </div>
              </div>
              <div class="fix-3-12 toLeft">
                <div class="team ae-5 fromBottom">
                  <div class="name">Scott Shi</div>
                  <div class="title">CTO, Co-Founder</div>
                  <div class="avatar">
                    <img src="img/team7.png" alt="" />
                  </div>
                  <p>
                    Gamer, Bridge Player, Math Competitions, Backend Engineer, 4
                    years at Uber, building data-related products from a one-man
                    shop to more than 200 ppl. And 4 years building a dataflow
                    orchestrator at Salesforce.
                  </p>
                </div>
                <div class="team ae-6 fromBottom">
                  <div class="name">Zoro</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team8.png" alt="" />
                  </div>
                  <p>
                    Before developing ZettaTrade, I studied Physics at Stanford.
                    The difference is in the area of physics, you observe
                    phenomena and try to deduct the rules behind the world. In
                    the crypto world, you know the rules and try to build a
                    phenomena dapp.
                  </p>
                </div>
                <div class="team ae-7 fromBottom">
                  <div class="name">Gautam</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team9.png" alt="" />
                  </div>
                  <p>Everything is about data.</p>
                </div>
              </div>
            </div>
            <div class="fix-12-12 teamList mobile">
              <div class="fix-6-12 toLeft">
                <div class="team ae-4 fromBottom">
                  <div class="name">Chi Zhang</div>
                  <div class="title">CEO, Co-Founder</div>
                  <div class="avatar">
                    <img src="img/team4.png" alt="" />
                  </div>
                  <p>
                    Data Geek, Professional Athlete, degrees in Math, Economics,
                    Biomedical and Statistics. Ph.D. from UC Berkeley. Before
                    Zettatrade, I was a product manager at Databricks, and a
                    founding data scientist at an Auto ML company.
                  </p>
                </div>
                <div class="team ae-3 fromBottom">
                  <div class="name">Jeff</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team1.png" alt="" />
                  </div>
                  <p>
                    Fullstack engineer who doesn’t like to write css. Worked in
                    several startups before, the website you saw is built by me
                    and designed by my friend. If you like the style, well, I
                    could make an intro to the designer.
                  </p>
                </div>
                <div class="team ae-4 fromBottom">
                  <div class="name">Erwin</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team2.png" alt="" />
                  </div>
                  <p>
                    Engineering, engineering, and engineering. I love building
                    things from scratch and teaching others about how to build
                    big data systems. Designed and built an analytics platform
                    that served 200+ banks.
                  </p>
                </div>
                <div class="team ae-5 fromBottom">
                  <div class="name">Fan</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team3.png" alt="" />
                  </div>
                  <p>
                    Blockchain developer, father of a little baby girl, and got
                    a Ph.D. in EE from UCLA. I love everything about Ethereum.
                    Worked on the Solana project unwillingly just for now.
                  </p>
                </div>
                <div class="team ae-7 fromBottom">
                  <div class="name">Gautam</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team9.png" alt="" />
                  </div>
                  <p>Everything is about data.</p>
                </div>
              </div>
              <div class="fix-6-12 toLeft">
                <div class="team ae-5 fromBottom">
                  <div class="name">Scott Shi</div>
                  <div class="title">CTO, Co-Founder</div>
                  <div class="avatar">
                    <img src="img/team7.png" alt="" />
                  </div>
                  <p>
                    Gamer, Bridge Player, Math Competitions, Backend Engineer, 4
                    years at Uber, building data-related products from a one-man
                    shop to more than 200 ppl. And 4 years building a dataflow
                    orchestrator at Salesforce.
                  </p>
                </div>
                <div class="team ae-6 fromBottom">
                  <div class="name">Zoro</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team8.png" alt="" />
                  </div>
                  <p>
                    Before developing ZettaTrade, I studied Physics at Stanford.
                    The difference is in the area of physics, you observe
                    phenomena and try to deduct the rules behind the world. In
                    the crypto world, you know the rules and try to build a
                    phenomena dapp.
                  </p>
                </div>
                <div class="team ae-5 fromBottom">
                  <div class="name">Peter</div>
                  <div class="title">Developer</div>
                  <div class="avatar">
                    <img src="img/team5.png" alt="" />
                  </div>
                  <p>
                    Cloud Architect, DevOps, SRE, System Engineer for startups
                    with 20 yoe.I build modern, scalable, geographically
                    distributed solutions focused on cloud transformation. Here
                    are many cool words like Devoooops ;)
                  </p>
                </div>
                <div class="team ae-6 fromBottom">
                  <div class="name">Rachel</div>
                  <div class="title">Marketing</div>
                  <div class="avatar">
                    <img src="img/team6.png" alt="" />
                  </div>
                  <p>
                    Developer, Music lover, went to graduate school at CMU. I’m
                    a passionate new grad. Proud to build a pipeline that
                    ingested 44 billion Solana transactions data within 2
                    months.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="background" style="background-image: url(assets/img/background/img-67.jpg)"></div>
    </section>
    <!-- Slide 5 (#62) -->
    <section class="slide slide5" id="slide5">
      <div class="content">
        <div class="container">
          <div class="wrap">
            <div class="fix-12-12 clearBoth">
              <div class="fix-8-12 head toCenter">
                <svg class="ae-2 fromBottom margin-bottom-4" style="width: 70px; height: 60px">
                  <use xlink:href="#logoshape"></use>
                </svg>
                <h3 class="ae-3 fromBottom margin-bottom-4">
                  Easy Trade with ZettaTrade
                </h3>
                <p class="ae-4 fromBottom">
                  The easiest way to take actions from the behavior and on-chain activity of prominent wallet addresses. With the powerful data infrastructure powered by ZettaBlock, advanced data driven trading has never been easier, and building a “bloomberg for web3” is becoming a walk in the park.
                </p>
              </div>
            </div>
            <div class="desktop">
              <form class="fix-12-12 subscribe" action="https://zettablock.us14.list-manage.com/subscribe/post?u=1ff0f35da3b86da52617aadd6&amp;id=bc40fdafb4" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                <input type="email" value="" name="EMAIL" id="mce-EMAIL" class="fix-8-12" placeholder="Interested in becoming an early user? Please Input E-mail Address." />
                <input style="display: none;" checked type="checkbox" value="4" name="group[45212][4]" id="mce-group[45212]-45212-2">
                <button class="button square fix-4-12">
                  <span href="">Become an Early User!
                    <svg style="width: 32px; height: 24px">
                      <use xlink:href="#arrowbutton"></use>
                    </svg></span>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="footer desktop">
          <div class="footer1">
            <div class="brand">
              <svg style="width: 330px; height: 44px">
                <use xlink:href="#logo"></use>
              </svg>
              <p>Trade with the Smart Money</p>
            </div>
            <div class="follow">
              <p>Follow us</p>
              <ul>
                <li>
                  <a target="_blank" href="https://twitter.com/ZettaTrade"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-twitter"></use>
                    </svg></a>
                </li>
                <li>
                  <a target="_blank" href="https://discord.gg/px42xPBFkN"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-discord"></use>
                    </svg></a>
                </li>
                <li>
                  <a target="_blank" href="https://github.com/zettablock"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-github"></use>
                    </svg></a>
                </li>
                <li>
                  <a target="_blank" href="https://zettablock.medium.com/"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-medium"></use>
                    </svg></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer2-wrap">
            <div class="footer2">
              <div class="copyright">© 2022 ZettaTrade</div>
              <div class="poweredby">
                Powered by
                <a href="https://zettablock.com" target="_blank">
                  <svg style="width: 195px; height: 32px">
                    <use xlink:href="#zettablock"></use>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer mobile">
          <div class="footer1">
            <div class="brand">
              <svg style="width: 330px; height: 44px">
                <use xlink:href="#logo"></use>
              </svg>
              <p>Trade with the Smart Money</p>
            </div>
            <div class="sub">
              <p>Become an Early User!</p>
              <form class="fix-12-12 subscribe" action="https://zettablock.us14.list-manage.com/subscribe/post?u=1ff0f35da3b86da52617aadd6&amp;id=bc40fdafb4" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                <input type="email" value="" name="EMAIL" id="mce-EMAIL" placeholder="Please Input E-mail Address." />
                <button class="button square">
                  <span href="">
                    <svg style="width: 18px; height: 14px">
                      <use xlink:href="#arrowbutton"></use>
                    </svg></span>
                </button>
              </form>
            </div>
            <div class="follow">
              <p>Follow us</p>
              <ul>
                <li>
                  <a target="_blank" href="https://twitter.com/ZettaTrade"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-twitter"></use>
                    </svg></a>
                </li>
                <li>
                  <a target="_blank" href="https://discord.gg/px42xPBFkN"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-discord"></use>
                    </svg></a>
                </li>
                <li>
                  <a target="_blank" href="https://github.com/zettablock"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-github"></use>
                    </svg></a>
                </li>
                <li>
                  <a target="_blank" href="https://zettablock.medium.com/"><svg style="width: 44px; height: 44px">
                      <use xlink:href="#social-medium"></use>
                    </svg></a>
                </li>
              </ul>
            </div>
            <div class="poweredby">
              <p>Powered by</p>
              <a href="https://zettablock.com" target="_blank">
                <svg style="width: 195px; height: 32px">
                  <use xlink:href="#zettablock"></use>
                </svg>
              </a>
            </div>
          </div>
          <div class="footer2-wrap">
            <div class="footer2">
              <div class="copyright">© 2022 ZettaTrade</div>
            </div>
          </div>
        </div>
      </div>
      <div class="background opacity-7">
        <video id="slide1-video" poster="img/slide5-bg.png" preload="auto" x5-video-player-type="h5" x5-playsinline webkit-playsinline playsinline muted autoplay loop>
          <source src="img/slide5-bg.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  </div>
</template>

<script>
  import { slidesFunction } from "../utils/slides_v5.2.1";
  import SvgLibrary from "../components/SvgLibrary.vue";
  import Panels from "../components/Panels.vue";

  export default {
    name: "home",
    components: {
      SvgLibrary,
      Panels,
    },
    data() {
      return {};
    },
    created() {
      document.title = "ZettaTrade - Trade with Smart Money";
    },
    mounted() {
      setTimeout(() => {
        this.onCreateView();
      }, 100);
    },
    watch: {},
    destroyed() {
      this.removeHome();
    },
    methods: {
      onCreateView() {
        document.querySelector("body").classList.add("posr");
        slidesFunction();
        window.scrollTo(0, 1);
      },
      removeHome() {
        $("body").removeClass("posr");
        $(window).off("mousemove");
      },
    },
  };
</script>

<style lang="scss">
  #slide1-video {
    width: 100%;
  }
  #SlidesContainer {
    max-width: 1920px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1920px) {
    .panel {
      max-width: 1920px;
      left: calc(50% - 960px);
    }
  }
  .posr {
    position: relative;
  }
  .slide1 {
    .stR {
      text-align: right;
      h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
        text-align: right;
        padding-left: 90px;
      }
    }
    .sb {
      padding-top: 40vh;
      .sbL {
        h3 {
          font-weight: 700;
          font-size: 44px;
          line-height: 1.1;
          span {
            display: inline-block;
            width: 120px;
            height: 32px;
            margin-right: 14px;
            background: #d5ff01;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              top: -32px;
              left: 0;
              width: 0px;
              height: 0px;
              border: 16px solid;
              border-color: transparent transparent #d5ff01;
            }
          }
        }
        h2 {
          font-weight: 700;
          font-size: 70px;
          line-height: 1.1;
        }
        .bList {
          display: flex;
          max-width: 450px;
          margin-top: 40px;
          .button {
            width: 230px;
            height: 60px;
            background: #d5ff01;
            flex: 1;
            padding: 20px;
            padding-right: 60px;
            text-align: center;
            background: linear-gradient(-70deg, transparent 25px, #d5ff01 0);
            margin-right: 0px;
            font-weight: 700;
            font-size: 20px;
            line-height: 1;
            color: #000000;
            border: 0;
            margin: 0;
          }
          .button:last-child {
            width: 230px;
            height: 60px;
            background: #d5ff01;
            flex: 1;
            padding: 20px;
            padding-left: 60px;
            text-align: center;
            background: linear-gradient(-70deg, #fff 205px, transparent 0);
            margin-right: 0px;
            font-weight: 700;
            font-size: 20px;
            line-height: 1;
            color: #000000;
            border: 0;
          }
        }
      }
      .sbR {
        text-align: right;

        .demoVideo {
          display: inline-block;
          width: auto;
          height: 200px;
          border: 6px solid #03ffe1;
          box-sizing: border-box;
          box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
          position: relative;
          overflow: unset;
          .playbutton {
            position: absolute;
            z-index: 9;
            top: calc(50% - 24px);
            left: calc(50% - 24px);
            opacity: 0.9;
            transition: all 0.2s;
            &:hover {
              opacity: 1;
            }
          }
          img {
            height: 100%;
            z-index: 1;
          }
          &:before {
            content: "";
            position: absolute;
            bottom: -38px;
            right: -6px;
            width: 0px;
            height: 0px;
            border: 16px solid;
            border-color: #03ffe1 transparent transparent;
          }
        }
        p {
          padding-right: 209px;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.4;
          color: #03ffe1;
        }
      }
    }
    .power {
      display: flex;
      gap: 30px;
      align-content: center;
      justify-content: center;
      margin-top: 20vh;
    }

    .background:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.5) 15.22%,
          rgba(0, 0, 0, 0) 50%
        ),
        linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 24.95%);
    }
  }
  .slide2 {
    .container {
      height: auto !important;
    }
    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 100%;
      color: #ffffff;
    }
    ul {
      margin-top: -50px;
      li {
        h5 {
          font-weight: 700;
          font-size: 24px;
          line-height: 100%;
          text-transform: uppercase;
          color: #ffffff;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #ffffff;
        }
      }
    }
  }
  .slide3 {
    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 100%;
      color: #ffffff;
    }
    .fix-5-12 {
      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 98.2%;
        text-transform: uppercase;
        color: #ffffff;
      }
      h3 {
        font-weight: 400;
        font-size: 32px;
        line-height: 98.2%;
        text-transform: uppercase;
        color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
      }
    }
    .key1 {
      margin-top: -130px;
    }
    .key2 {
      margin-top: -40px;
    }
    .key3 {
      margin-top: -80px;
    }
  }
  .slide4 {
    .head {
      position: absolute;
      top: 100px;
      h3 {
        font-weight: 700;
        font-size: 56px;
        line-height: 100%;
        color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
      }
    }
    .mobile {
      display: none !important;
    }
    .teamList {
      display: flex;
      gap: 30px;
      position: absolute;
      top: 100px;
      .fix-3-12:nth-child(1) {
        padding-top: 460px;
      }
      .fix-3-12:nth-child(2) {
        padding-top: 400px;
      }
      .fix-3-12:nth-child(3) {
        padding-top: 20px;
      }
      .fix-3-12:nth-child(4) {
        padding-top: 140px;
      }
      .team {
        padding: 20px;
        background: rgba(47, 50, 65, 0.5);
        backdrop-filter: blur(64px);
        border-radius: 20px;
        position: relative;
        margin-bottom: 30px;
        .name {
          text-align: left;
          font-weight: 700;
          font-size: 24px;
          line-height: 100%;
          margin-bottom: 5px;
        }
        .title {
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          display: none;
        }
        .avatar {
          width: 40px;
          height: 40px;
          background: #000;
          position: absolute;
          top: 20px;
          right: 20px;
          border-radius: 9px;
          overflow: hidden;
        }
        p {
          text-align: left;
          font-weight: 400;
          font-size: 12px;
          line-height: 180%;
          margin-top: 20px;
          opacity: 0.8;
        }
      }
    }
  }
  .slide5 {
    .content {
      .container {
        height: auto !important;
        .wrap {
          padding-bottom: 0;
        }
      }
    }
    .head {
      margin-bottom: 20vh;
      h3 {
        font-weight: 700;
        font-size: 56px;
        line-height: 100%;
        color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
      }
    }
    .mobile {
      display: none !important;
    }
    .subscribe {
      display: flex;
      gap: 0;
      input {
        display: block;
        border: 0;
        border-radius: 0;
        background: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        height: 50px;
        padding: 8px 20px;
        margin: 0 !important;
        &::placeholder {
          color: rgba(0, 0, 0, 0.2);
        }
      }
      .button {
        display: block;
        margin: 0 !important;
        background: #d5ff01;
        color: #000;
        box-shadow: none;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        line-height: 50px;
        letter-spacing: 0.5px;
        padding: 0;
        margin-bottom: 0;
        overflow: unset;
        z-index: 9;
        &:before {
          content: "";
          position: absolute;
          bottom: -36px;
          right: 0;
          width: 0px;
          height: 0px;
          border: 18px solid;
          border-color: #d5ff01 transparent transparent;
        }
        svg {
          margin-left: 26%;
          margin-right: 0;
        }
      }
    }
    .footer {
      width: 100%;
      height: 320px;
      background: linear-gradient(180deg, #2a2a2a 0%, rgba(42, 42, 42, 0) 74.74%, #03ffe1 119.85%),
        #000000;
      position: relative;
      z-index: 9;
    }
    .footer1 {
      max-width: 1170px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding-top: 30px;
      .brand {
        padding-top: 20px;
        width: 50%;
        p {
          margin-top: 10px;
          font-weight: 700;
          font-size: 23px;
          line-height: 150%;
          letter-spacing: 0px;
        }
      }
      .follow {
        padding-top: 20px;
        p {
          margin-top: 10px;
          font-weight: 700;
          font-size: 24px;
          line-height: 100%;
          letter-spacing: 0.5px;
          margin-bottom: 20px;
          margin-top: 0;
        }
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          gap: 20px;
          li {
          }
        }
      }
    }
    .footer2-wrap {
      width: 100%;
      height: 80px;
      background: #03ffe1;
      position: absolute;
      bottom: 0;
      z-index: 10;

      .footer2 {
        max-width: 1170px;
        height: 80px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .copyright {
          font-weight: 700;
          font-size: 20px;
          line-height: 100%;
          color: #000000;
        }
        .poweredby {
          font-weight: 700;
          font-size: 20px;
          line-height: 100%;
          color: #000000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          svg {
            margin-left: 20px;
          }
        }
      }
    }
    .background:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 47.62%),
        linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 43.39%);
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
  }
  @media screen and (max-width: 767px) {
    .container .wrap:not(.noSpaces),
    .container .wrap.spaceWrap,
    .container .wrap .spaceWrap {
      padding: 80px 20px;
    }
    .slide1 {
      .container {
        height: auto;
        padding-bottom: 260px;
        overflow: hidden;
        .wrap {
          overflow: unset;
          height: auto;
        }
      }
      .stR h4 {
        font-weight: bold;
        font-size: 14px;
        line-height: 160%;
      }
      .sb {
        padding-top: calc(100vh - 420px);
        .sbL {
          h3 {
            display: flex;
            justify-content: space-between;
            font-size: 32px;
            white-space: nowrap;
            line-height: 1;
            span {
              height: 30px;
            }
          }
          h2 {
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
            font-size: 52px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .bList {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .button {
              width: 50%;
              height: 44px;
              padding: 14px 20px;
              font-size: 16px;
              background: linear-gradient(-70deg, transparent 10%, #d5ff01 0);
              &:last-child {
                width: 50%;
                height: 44px;
                padding: 14px 20px;
                font-size: 16px;
                background: linear-gradient(-70deg, #fff 90%, transparent 0);
              }
            }
          }
        }
        .sbR {
          position: absolute;
          max-width: calc(100vw - 80px) !important;
          left: 40px;
          bottom: 0px;
          .demoVideo {
            width: 100%;
            height: auto;
          }
          p {
            padding-right: 30%;
            text-align: left;
          }
        }
      }
      .power {
        margin-top: 80px;
        gap: 0px;
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          text-transform: uppercase;
          margin: 0 8px;
        }
        img {
          width: 100px;
          height: 16px;
          margin: 0 8px;
        }
      }
      .background {
        width: 100%;
        video {
          width: auto;
          height: 75vh;
          position: relative;
          left: -40vh;
        }
        &:after {
          height: 75vh;
        }
      }
    }
    .slide2 {
      h3 {
        font-size: 32px;
      }
      ul {
        margin-top: 20px;
        &.flex.reverse {
          flex-wrap: wrap;
        }
        li {
          margin-top: 50px !important;
          position: relative;
          &:before {
            content: "";
            width: calc(100% - 90px);
            height: 1px;
            display: block;
            position: absolute;
            right: 0;
            bottom: -25px;
            background: #fff;
            opacity: 0.2;
          }
          &:last-child {
            &:before {
              display: none;
            }
          }
          svg {
            position: absolute;
            top: 2px;
            left: 10px;
          }
          h5 {
            padding-left: 80px;
            margin: 0 !important;
            font-size: 20px;
          }
          p {
            padding-left: 80px;
            margin-top: 10px;
            font-size: 14px;
          }
        }
      }
    }
    .slide3 {
      .wrap {
        padding-bottom: 0px !important;
      }
      h3 {
        font-size: 32px;
      }
      .fix-12-12 {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 60px !important;
        gap: 30px;
        &:nth-child(3) {
          flex-direction: column;
        }
        .keyInfo {
          position: relative;
          svg {
            width: 44px !important;
            height: 50px !important;
            position: absolute;
            left: 0;
            top: 0;
          }
          h2 {
            padding-left: 60px;
            font-size: 28px;
            margin-top: 0 !important;
            margin-bottom: 5px !important;
            text-align: left;
          }
          h3 {
            padding-left: 60px;
            font-size: 20px;
            margin-top: 0 !important;
            margin-bottom: 15px !important;
            text-align: left;
          }
          p {
            padding-left: 60px !important;
            font-size: 13px;
            line-height: 1.6;
            text-align: left;
          }
        }
        .key1,
        .key2,
        .key3 {
          margin-top: 0;
          img {
            width: 90%;
          }
        }
      }
    }
    .slide4 {
      .container {
        height: auto;
        padding-bottom: 0px;
        overflow: hidden;
        .wrap {
          overflow: unset;
          height: auto;
          padding-bottom: 0;
        }
      }
      .head {
        position: relative;
        top: 0;
        h3 {
          font-size: 32px;
        }
        p {
          font-size: 13px;
          line-height: 1.6;
        }
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: flex !important;
        position: relative;
        top: 0;
        margin-top: 40px;
        gap: 15px;
        .fix-6-12:last-child {
          padding-top: 40px;
        }
        .team {
          border-radius: 6px;
          padding: 15px;
          margin-bottom: 15px;
          .name {
            font-size: 16px;
          }
          .avatar {
            top: 15px;
            right: 15px;
            width: 28px;
            height: 28px;
            border-radius: 4px;
          }
          p {
            font-size: 10px;
            line-height: 1.4;
            opacity: 0.6;
          }
        }
      }
    }
    .slide5 {
      .content,
      .container {
        height: auto;
        padding-bottom: 0px;
        overflow: hidden;
        .wrap {
          overflow: unset;
          height: auto;
          padding-bottom: 0;
        }
      }
      .head {
        svg {
          margin-bottom: 20px !important;
        }
        h3 {
          font-size: 32px;
          line-height: 1.4;
          margin-bottom: 20px !important;
        }
        p {
          font-size: 16px;
          line-height: 2;
        }
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: block !important;
        height: auto;
        .footer1 {
          flex-direction: column;
          padding: 80px 20px 120px;
          gap: 40px;
          .brand {
            padding-top: 0;
            width: 100%;
            text-align: center;
            svg {
              width: 214px !important;
              height: 28px !important;
            }
            p {
              font-size: 16px;
              line-height: 1;
            }
          }
          .sub {
            width: 90%;
            p {
              font-weight: 700;
              font-size: 16px;
              line-height: 1;
              text-align: center;
              margin-bottom: 20px;
            }
            form {
              position: relative;
              input {
                width: 100%;
                padding: 8px 44px 8px 12px;
                height: 44px;
                border-radius: 4px;
              }
              button {
                position: absolute;
                right: 0px;
                background: transparent;
                width: 44px;
                height: 44px;
                line-height: 44px;
                &:before {
                  display: none;
                }
              }
            }
          }
          .follow {
            p {
              font-size: 16px;
              line-height: 1;
              text-align: center;
            }
          }
          .poweredby {
            p {
              font-size: 16px;
              line-height: 1;
              text-align: center;
              font-weight: 700;
              margin-bottom: 20px;
            }
          }
        }
        .footer2-wrap {
          height: 50px;
          .footer2 {
            height: 50px;
            .copyright {
              width: 100%;
              font-size: 14px;
              line-height: 2;
              text-align: center;
            }
          }
        }
      }
      .background {
        width: 100%;
        video {
          width: auto;
          height: 75vh;
          position: relative;
          left: -40vh;
        }
        &:after {
          height: 75vh;
        }
      }
    }
  }
</style>
